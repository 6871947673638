export const svgIcons={
	'facebook': `<svg id="logo-Facebook" viewBox="0 0 19 19"><g class="nc-icon-wrapper"><path fill="#3E5C9B" fill-rule="evenodd" d="M16.662 2.25H2.382c-.34 0-.632.29-.632.63v14.192c0 .388.291.678.631.678h7.626v-5.812H8.065V9.516h1.942V7.579c0-1.986 1.263-3.004 3.06-3.004.875 0 1.603.097 1.797.097v2.083h-1.263c-.97 0-1.214.484-1.214 1.163v1.598h2.43l-.487 2.422h-1.943l.049 5.812h4.176c.341 0 .633-.29.633-.63V2.88c.048-.34-.243-.63-.583-.63"></path></g></svg>`,
	'twitter': `<svg id="logo-Twitter" viewBox="0 0 19 19"><g class="nc-icon-wrapper"><path fill="#1DA1F2" fill-rule="evenodd" d="M18.25 4.448c-.654.274-1.309.493-2.072.549.763-.44 1.308-1.152 1.581-1.973-.709.439-1.471.712-2.29.877-.654-.713-1.58-1.151-2.562-1.151A3.605 3.605 0 0 0 9.31 6.367c0 .274.055.547.11.822C6.42 7.025 3.803 5.6 2.004 3.407a3.459 3.459 0 0 0-.491 1.81c0 1.26.655 2.356 1.58 3.013a3.317 3.317 0 0 1-1.635-.439v.056c0 1.753 1.254 3.179 2.89 3.507-.327.055-.6.11-.927.11-.217 0-.435 0-.653-.055.436 1.426 1.798 2.466 3.325 2.522-1.2.985-2.78 1.534-4.47 1.534-.273 0-.6 0-.873-.054C2.385 16.397 4.294 17 6.311 17c6.596 0 10.195-5.481 10.195-10.25v-.438c.654-.548 1.253-1.151 1.744-1.864"></path></g></svg>`,
	'email': `<svg id="ui-envelope" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><path d="M15.783 15.25c.27 0 .467-.177.467-.365v-8.77c0-.188-.198-.365-.467-.365H3.217c-.27 0-.467.177-.467.365v8.77c0 .188.198.365.467.365zm0 1H3.217c-.798 0-1.467-.598-1.467-1.365v-8.77c0-.767.669-1.365 1.467-1.365h12.566c.798 0 1.467.598 1.467 1.365v8.77c0 .767-.669 1.365-1.467 1.365zm-1.065-9.413a.5.5 0 1 1 .564.826l-5.5 3.75a.5.5 0 0 1-.564 0l-5.5-3.75a.5.5 0 1 1 .564-.826L9.5 10.395z" fill-rule="evenodd"></path></g></svg>`,
	'whatsapp': `<svg id="logo-Whatsapp" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><path fill-rule="evenodd" d="M13.05 11.538c-.192-.096-1.14-.56-1.317-.624-.176-.064-.305-.096-.434.096-.128.192-.498.625-.61.753-.113.128-.225.144-.418.048-.193-.096-.814-.299-1.55-.953a5.788 5.788 0 0 1-1.073-1.33c-.113-.191-.012-.295.084-.391.087-.086.193-.224.29-.336.096-.112.128-.192.192-.32.064-.129.032-.24-.016-.337-.048-.096-.434-1.04-.594-1.425-.157-.374-.316-.323-.434-.329a7.771 7.771 0 0 0-.37-.007.71.71 0 0 0-.514.24c-.177.192-.675.657-.675 1.601 0 .945.69 1.858.787 1.986.097.128 1.36 2.066 3.295 2.897.46.198.819.316 1.099.405.462.146.882.125 1.214.076.37-.055 1.141-.464 1.302-.913.16-.448.16-.832.112-.912-.048-.08-.176-.128-.37-.225M9.533 16.32H9.53a6.428 6.428 0 0 1-3.265-.89l-.234-.139-2.428.634.648-2.355-.153-.242a6.346 6.346 0 0 1-.98-3.397c.001-3.52 2.879-6.384 6.417-6.384 1.713 0 3.324.666 4.535 1.872a6.33 6.33 0 0 1 1.877 4.517c-.002 3.52-2.879 6.384-6.415 6.384m5.46-11.817a7.686 7.686 0 0 0-5.46-2.253c-4.253 0-7.716 3.445-7.717 7.68 0 1.354.354 2.675 1.03 3.84L1.75 17.75l4.091-1.068a7.74 7.74 0 0 0 3.688.935h.003c4.254 0 7.716-3.446 7.718-7.68a7.616 7.616 0 0 0-2.258-5.434"></path></g></svg>`,
	'messenger': `<svg id="logo-Messenger" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><path fill-rule="evenodd" d="M9.5 2.25c-4.366 0-7.75 3.198-7.75 7.518 0 2.26.926 4.212 2.434 5.56a.624.624 0 0 1 .21.442l.041 1.378c.014.44.468.725.87.55l1.538-.68a.618.618 0 0 1 .413-.03 8.43 8.43 0 0 0 2.244.298c4.366 0 7.75-3.199 7.75-7.518 0-4.32-3.384-7.518-7.75-7.518zm-4.44 9.94l2.295-3.564A1.188 1.188 0 0 1 9.05 8.32l1.825 1.34a.477.477 0 0 0 .564-.002l2.464-1.83c.328-.246.759.14.537.482l-2.293 3.563a1.188 1.188 0 0 1-1.694.306l-1.824-1.34a.477.477 0 0 0-.564.001l-2.466 1.833c-.328.245-.759-.142-.537-.483z"></path></g></svg>`,
	'textsharing': `<svg id="ui-message" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><path d="M5.617 13.821h-2.4A1.475 1.475 0 0 1 1.75 12.34V4.482C1.75 3.666 2.405 3 3.217 3h12.566c.812 0 1.467.666 1.467 1.482v7.857c0 .817-.655 1.482-1.467 1.482h-4.968l-4.37 3.806a.5.5 0 0 1-.828-.377zm1 2.33l3.683-3.207a.5.5 0 0 1 .328-.123h5.155a.475.475 0 0 0 .467-.482V4.482A.475.475 0 0 0 15.783 4H3.217a.475.475 0 0 0-.467.482v7.857c0 .268.211.482.467.482h2.9a.5.5 0 0 1 .5.5zM4.866 7.376a.5.5 0 1 1 0-1h9.174a.5.5 0 1 1 0 1zm0 3.25a.5.5 0 1 1 0-1h6.018a.5.5 0 0 1 0 1z" fill-rule="evenodd"></path></g></svg>`,
	'instagram': `<svg id="logo-Instagram" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><path fill-rule="evenodd" d="M8.327 2.343h2.295a38.71 38.71 0 0 1 2.079.046c1.271.058 2.387.37 3.284 1.266.896.897 1.209 2.014 1.267 3.286.036.797.046 1.075.046 2.958v.533c0 1.883-.01 2.161-.046 2.96-.058 1.272-.37 2.388-1.266 3.285-.898.896-2.014 1.208-3.286 1.266-.797.036-1.076.045-2.959.046h-.533c-1.883-.001-2.16-.01-2.96-.046-1.27-.058-2.388-.37-3.285-1.266-.896-.897-1.208-2.014-1.265-3.285-.03-.654-.041-.959-.045-2.08V9.019a38.68 38.68 0 0 1 .045-2.077c.058-1.273.37-2.39 1.265-3.286.897-.896 2.014-1.208 3.285-1.266.654-.03.959-.042 2.08-.046h2.294zm2.083 1.41H8.54a39.543 39.543 0 0 0-2.225.045c-.891.04-1.718.219-2.353.855-.635.635-.814 1.46-.854 2.352-.032.68-.043.968-.046 2.225v1.871c.003 1.257.014 1.544.046 2.226.04.89.218 1.717.853 2.353.636.636 1.463.814 2.354.854.789.036 1.049.045 2.898.046h.525c1.85-.001 2.11-.01 2.899-.046.89-.04 1.716-.218 2.353-.854.634-.636.813-1.462.853-2.353.037-.79.046-1.05.046-2.9v-.524c0-1.849-.01-2.11-.046-2.898-.04-.891-.219-1.717-.854-2.352-.636-.636-1.461-.815-2.352-.855-.681-.032-.969-.043-2.226-.046zm-.935 2.395a4.018 4.018 0 1 1 0 8.036 4.018 4.018 0 0 1 0-8.036zm0 1.41a2.608 2.608 0 1 0 0 5.215 2.608 2.608 0 0 0 0-5.215zm4.176-2.507a.938.938 0 1 1-.939.938c0-.518.42-.938.94-.938z"></path></g></svg>`,
	'slack': `<svg id="logo-Slack" viewBox="0 0 19 19"><g class="nc-icon-wrapper"><g fill="none" transform="translate(1.75 2.25)"><path d="M5.683 0a1.55 1.55 0 1 0 0 3.1h1.55V1.55A1.55 1.55 0 0 0 5.683 0zm0 4.133H1.55a1.55 1.55 0 0 0 0 3.1h4.134a1.55 1.55 0 1 0 0-3.1" fill="#36c5f0"></path><path d="M15.5 5.684a1.55 1.55 0 1 0-3.1 0v1.55h1.55a1.55 1.55 0 0 0 1.55-1.55zm-4.133 0V1.55a1.55 1.55 0 0 0-3.1 0v4.133a1.55 1.55 0 0 0 3.1 0" fill="#2eb67d"></path><path d="M9.816 15.5a1.55 1.55 0 1 0 0-3.1h-1.55v1.55a1.55 1.55 0 0 0 1.55 1.55zm0-4.133h4.134a1.55 1.55 0 0 0 0-3.1H9.816a1.55 1.55 0 1 0 0 3.1z" fill="#ecb22e"></path><path d="M0 9.816a1.55 1.55 0 1 0 3.1 0v-1.55H1.55A1.55 1.55 0 0 0 0 9.816zm4.133 0v4.134a1.55 1.55 0 0 0 3.1 0V9.816a1.55 1.55 0 1 0-3.1 0z" fill="#e01e5a"></path></g></g></svg>`,
	'home': `<svg id="logo-Nextdoor" viewBox="0 0 19 19"><g class="nc-icon-wrapper"><path d="M9.605 3.5L.75 9.03l1.476 2.339 1.476-.85v7.231h11.596v-7.231l1.476.85L18.25 9.03l-2.952-1.914V3.713h-2.741v1.701z" fill="#00b340"></path></g></svg>`,
	'moreOptions': `<svg id="ui-ellipsis" viewBox="0 0 19 19"><g class="nc-icon-wrapper" stroke="none" fill="currentColor"><g fill-rule="evenodd"><circle cx="9.75" cy="10.5" r="1.75"></circle><circle cx="16.75" cy="10.5" r="1.75"></circle><circle cx="2.75" cy="10.5" r="1.75"></circle></g></g></svg>`,

}