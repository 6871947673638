/* Auth */
export const LOGIN = 'LOGIN'
export const SUPER_LOGIN = 'SUPER_LOGIN'
export const LOGIN_START = 'LOGIN_START'
export const SUPER_LOGIN_START = 'SUPER_LOGIN_START'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_UP_AVATAR_TO_STORE = 'SET_UP_AVATAR_TO_STORE'
export const SET_UP_AVATAR_URL_TO_USER = 'SET_UP_AVATAR_URL_TO_USER'
export const FOLLOW_AUTH_USER = 'FOLLOW_AUTH_USER'
export const REGISTER = 'REGISTER'
export const CREATE_AVATAR = 'CREATE_AVATAR'
export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

/* Users */
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_START = 'CREATE_USER_START'
export const GET_MY_PROFILE = 'GET_MY_PROFILE'
export const GET_ME = 'GET_ME'
export const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE'
export const SET_USER_PROFILE_PICTURE = 'SET_USER_PROFILE_PICTURE'
export const DELETE_PROFILE_PICTURE = 'DELETE_PROFILE_PICTURE'
export const SUBMIT_REVIEW_PROFILE = 'SUBMIT_REVIEW_PROFILE'
export const GET_REIVEW_LIST = 'GET_REIVEW_LIST'
export const SET_REVIEW_LIST = 'SET_REVIEW_LIST'
export const CLEAR_REVIEW_LIST = 'CLEAR_REVIEW_LIST'
export const GET_DONOR_GIVE_INFO = 'GET_DONOR_GIVE_INFO'
export const SET_DONOR_GIVE_INFO = 'SET_DONOR_GIVE_INFO'
export const SET_VERIFY_STEP = 'SET_VERIFY_STEP'
export const PAYOUT_STRIPE = 'PAYOUT_STRIPE'
export const SET_IS_CLAIMED = 'SET_IS_CLAIMED'
export const WITHDRAW_AMOUNT = 'WITHDRAW_AMOUNT'

/* Notification */
export const NOTIFICATION_TOGGLE = 'NOTIFICATION_TOGGLE'
export const NOTIFICATION_RESEND = 'NOTIFICATION_RESEND'

/* Follows */
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION'
export const SET_USER_FOLLOWERS = 'SET_USER_FOLLOWERS'
export const GET_USER_FOLLOWERS = 'GET_USER_FOLLOWERS'
export const GET_USER_FOLLOWINGS = 'GET_USER_FOLLOWINGS'
export const SET_USER_FOLLOWINGS = 'SET_USER_FOLLOWINGS'
export const CLEAR_FOLLOW_LIST = 'CLEAR_FOLLOW_LIST'

export const FOLLOW_USER_START = 'FOLLOW_USER_START'
export const FOLLOW_USER = 'FOLLOW_USER'

export const UNFOLLOW_USER_START = 'UNFOLLOW_USER_START'
export const UNFOLLOW_USER = 'UNFOLLOW_USER'

export const UPDATE_FOLLOW_STATUS = 'UPDATE_FOLLOW_STATUS'
export const NOTIFICATION_FOLLOW = 'NOTIFICATION_FOLLOW'
export const NOTIFICATION_FOLLOWING = 'NOTIFICATION_FOLLOWING'
export const NOTIFICATION_UNFOLLOW = 'NOTIFICATION_UNFOLLOW'

export const UPDATE_USER_RATING = 'UPDATE_USER_RATING'

/* Project */
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const CLEAR_PROJECT = 'CLEAR_PROJECT'
export const SET_REQUEST_CRETE_PROJECT = 'SET_REQUEST_CRETE_PROJECT'
export const SET_CURRENT_NEEDS_USERS = 'SET_CURRENT_NEEDS_USERS'
export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID'
export const SET_ONLOAD_PROJECT = 'SET_ONLOAD_PROJECT'
export const GET_PROJECTS = 'GET_PROJECTS'
export const SET_PROJECTS = 'SET_PROJECTS'
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS'
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS'
export const CONFIRM_OR_REJECT_NEED_PARTICIPATION = 'CONFIRM_OR_REJECT_NEED_PARTICIPATION'
export const GET_NEED_PARTICIPATION_LIST = 'GET_NEED_PARTICIPATION_LIST'
export const PROJECT_SUBSCRIPTION = 'PROJECT_SUBSCRIPTION'
export const CLEAR_PROJECTS_LIST = 'CLEAR_PROJECTS_LIST'
export const SET_CHANGES_NEED_APPLY = 'SET_CHANGES_NEED_APPLY'
export const SET_NEED_PARTICIPATION_VALUE = 'SET_NEED_PARTICIPATION_VALUE'
export const REQUEST_NEED_DONATION = 'REQUEST_NEED_DONATION'
export const UPDATE_NEED_PARTICIPATIONS = 'UPDATE_NEED_PARTICIPATIONS'
export const GET_REQUESTS = 'GET_REQUESTS'
export const SET_REQUESTS = 'SET_REQUESTS'
export const CLEAR_REQUESTS = 'CLEAR_REQUESTS'
export const GET_PROJECT_REQUESTS = 'GET_PROJECT_REQUESTS'
export const SET_PROJECT_REQUESTS = 'SET_PROJECT_REQUESTS'
export const CLEAR_PROJECT_REQUESTS = 'CLEAR_PROJECT_REQUESTS'
export const DONATE_MONEY = 'DONATE_MONEY'
export const SET_DONATE_ID = 'SET_DONATE_ID'
export const CLEAR_DONATE_ID = 'CLEAR_DONATE_ID'
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA'
export const SET_VOLUNTEER_ACTIVITY = 'SET_VOLUNTEER_ACTIVITY'
export const UPDATE_REQUEST_PARTICIPATION = 'UPDATE_REQUEST_PARTICIPATION'
export const UPDATE_VOLUNTEER_REQUEST = 'UPDATE_VOLUNTEER_REQUEST'
export const UPDATE_PICKUP_REQUEST = 'UPDATE_PICKUP_REQUEST'
export const SEND_RECEIPT = 'SEND_RECEIPT'
export const TURNOFF_PROJECT = 'TURNOFF_PROJECT'
export const CANCEL_PROJECT = 'CANCEL_PROJECT'
export const SET_PROJECT_TURN_ON_OFF_STATUS = 'SET_PROJECT_TURN_ON_OFF_STATUS'
export const SET_PROJECT_CANCEL_RECOVER_STATUS = 'SET_PROJECT_CANCEL_RECOVER_STATUS'
export const SAVE_CARD_INFO = 'SAVE_CARD_INFO'
export const GET_SAVED_CARDS = 'GET_SAVED_CARDS'
export const SET_SAVED_CARDS = 'SET_SAVED_CARDS'

/* Setting */
export const CHANGE_EMAIL_SETTING = 'CHANGE_EMAIL_SETTING'
export const CHANGE_PASSWORD_SETTING = 'CHANGE_PASSWORD_SETTING'
export const CHANGE_AVATAR_SETTING = 'CHANGE_AVATAR_SETTING'
export const CHANGE_DONOR_SETTING = 'CHANGE_DONOR_SETTING'
export const CHANGE_STUDENT_SETTING = 'CHANGE_STUDENT_SETTING'
export const CHANGE_NONPROFIT_SETTING = 'CHANGE_NONPROFIT_SETTING'
export const UPDATE_AUTH_NONPROFIT = 'UPDATE_AUTH_NONPROFIT'
export const UPDATE_AUTH_DONOR = 'UPDATE_AUTH_DONOR'
export const UPDATE_AUTH_STUDENT = 'UPDATE_AUTH_STUDENT'
export const CHANGE_PRIVACY_SETTING = 'CHANGE_PRIVACY_SETTING'
export const CHANGE_PROFILE_INFO = 'CHANGE_PROFILE_INFO'

/* Preloader */
export const PRELOADER_TOGGLE = 'PRELOADER_TOGGLE'

/* Global */
export const GET_SCHOOLS = 'GET_SCHOOLS'
export const GET_SINGLE_SCHOOL = 'GET_SINGLE_SCHOOL'
export const SET_SCHOOLS = 'SET_SCHOOLS'
export const SET_SINGLE_SCHOOL = 'SET_SINGLE_SCHOOL'
export const GET_INTERESTS = 'GET_INTERESTS'
export const SET_INTERESTS = 'SET_INTERESTS'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST'
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const READ_NOTIFICATION_LIST = 'READ_NOTIFICATION_LIST'
export const SET_UNREAD_NOTIFY = 'SET_UNREAD_NOTIFY'
export const GET_HIERARCHY_INTERESTS = 'GET_HIERARCHY_INTERESTS'
export const SET_HIERARCHY_INTERESTS = 'SET_HIERARCHY_INTERESTS'
export const SEND_SHOW_INVITE_NONPROFIT_DIALOG_MSG = 'SEND_SHOW_INVITE_NONPROFIT_DIALOG_MSG'
export const RECEIVE_SHOW_INVITE_NONPROFIT_DIALOG_MSG = 'RECEIVE_SHOW_INVITE_NONPROFIT_DIALOG_MSG'
export const SEND_HIDE_INVITE_NONPROFIT_DIALOG_MSG = 'SEND_HIDE_INVITE_NONPROFIT_DIALOG_MSG'
export const RECEIVE_HIDE_INVITE_NONPROFIT_DIALOG_MSG = 'RECEIVE_HIDE_INVITE_NONPROFIT_DIALOG_MSG'
export const GET_ACTIVE_PROJECT_TYPE = 'GET_ACTIVE_PROJECT_TYPE'
export const SET_ACTIVE_PROJECT_TYPE = 'SET_ACTIVE_PROJECT_TYPE'
export const SEND_NEED_VERIFY_EMAIL = 'SEND_NEED_VERIFY_EMAIL'

/* Game */
export const GET_QUESTION = 'GET_QUESTION'
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER'
export const SET_QUESTION = 'SET_QUESTION'
export const GET_GAME_STATS = 'GET_GAME_STATS'
export const SET_GAME_STATS = 'SET_GAME_STATS'

/* User */
export const SEND_USER_SUBSCRIBE = 'SEND_USER_SUBSCRIBE'
export const SEND_USER_CONTACT = 'SEND_USER_CONTACT'
export const SET_USER_SUBSCRIBE = 'SET_USER_SUBSCRIBE'
export const SET_USER_CONTACT = 'SET_USER_CONTACT'
export const CLEAR_USER_SUBSCRIBE = 'CLEAR_USER_SUBSCRIBE'
export const CLEAR_USER_CONTACT = 'CLEAR_USER_CONTACT'
export const CANCEL_VERIFICATION = 'CANCEL_VERIFICATION'
export const SHOWN_APPROVE_MESSAGE = 'SHOWN_APPROVE_MESSAGE'
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS'
export const HIDE_APPROVE_MESSAGE = 'HIDE_APPROVE_MESSAGE'
export const GET_USER_LIST = 'GET_USER_LIST'
export const SET_USER_LIST = 'SET_USER_LIST'
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST'
export const SET_DONOR_AVATAR = 'SET_DONOR_AVATAR'
export const UPDATE_DONOR_AVATAR = 'UPDATE_DONOR_AVATAR'
export const UPDATE_NONPROFIT_STATUS = 'UPDATE_NONPROFIT_STATUS'
export const GET_STRIPE_ACCOUNT_INFO = 'GET_STRIPE_ACCOUNT_INFO'
export const SET_STRIPE_CONNECT_RESULT = 'SET_STRIPE_CONNECT_RESULT'
export const UPDATE_FIRST_TIME = 'UPDATE_FIRST_TIME'
export const CHECK_HINTS = 'CHECK_HINTS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SUPER_LOGIN_FAILED = 'SUPER_LOGIN_FAILED'
export const INVITE_NONPROFIT = 'INVITE_NONPROFIT'
export const UNREAD_PROJECTS = 'UNREAD_PROJECTS'
export const SET_UNREAD_PROJECTS = 'SET_UNREAD_PROJECTS'
export const GET_COMMUNITY_LIST = 'GET_COMMUNITY_LIST'
export const CLEAR_COMMUNITY_LIST = 'CLEAR_COMMUNITY_LIST'
export const SET_COMMUNITY_LIST = 'SET_COMMUNITY_LIST'
export const GET_GUEST_CHARITY_LIST = 'GET_GUEST_CHARITY_LIST'
export const SET_GUEST_CHARITY_LIST = 'SET_GUEST_CHARITY_LIST'
export const ADD_GUEST_CHARITIES = 'ADD_GUEST_CHARITIES'
export const ADD_GUEST_CHARITIES_FROM_CSV = 'ADD_GUEST_CHARITIES_FROM_CSV'

/* Post */
export const GET_POSTS = 'GET_POSTS'
export const SET_POSTS = 'SET_POSTS'
export const SET_POP_AUTHORS = 'SET_POP_AUTHORS'
export const SET_POP_NEWS = 'SET_POP_NEWS'
export const CREATE_POST = 'CREATE_POST'
export const EDIT_POST = 'EDIT_POST'
export const OPEN_POST = 'OPEN_POST'
export const GET_POST_BY_ID = 'GET_POST_BY_ID'
export const CLOSE_POST = 'CLOSE_POST'
export const SET_REQUEST_CRETE_POST = 'SET_REQUEST_CRETE_POST'
export const CLEAR_POST_LIST = 'CLEAR_POST_LIST'
export const POST_LIKE = 'POST_LIKE'
export const POST_COMMENT = 'POST_COMMENT'
export const SHARE_POST = 'SHARE_POST'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const GET_POPULAR_AUTHORS = 'GET_POPULAR_AUTHORS'
export const GET_POPULAR_NEWS = 'GET_POPULAR_NEWS'
export const DELETE_POST = 'DELETE_POST'
export const GET_MEDIA_LIST = 'GET_MEDIA_LIST'
export const SET_MEDIA_LIST = 'SET_MEDIA_LIST'
export const CLEAR_MEDIA_LIST = 'CLEAR_MEDIA_LIST'
export const GET_MY_SHARED_POSTS = 'GET_MY_SHARED_POSTS'
export const SET_MY_SHARED_POSTS = 'SET_MY_SHARED_POSTS'
export const CLEAR_MY_SHARED_POST_LIST = 'CLEAR_MY_SHARED_POST_LIST'
export const CURRENT_POST = 'CURRENT_POST'

/* Contact */
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST'
export const CLEAR_CONTACT_LIST = 'CLEAR_CONTACT_LIST'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const UPLOAD_CONTACT_LIST = 'UPLOAD_CONTACT_LIST'
export const SET_NEW_CONTACT = 'SET_NEW_CONTACT'
export const CLEAR_NEW_CONTACT = 'CLEAR_NEW_CONTACT'
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL'
export const SET_CONTACT_DETAIL = 'SET_CONTACT_DETAIL'
export const UPLOAD_CONTACT_CSV = 'UPLOAD_CONTACT_CSV'
export const SEND_INVITE_CONTACT = 'SEND_INVITE_CONTACT'
export const SEND_INVITE_CONTACT_NOTIFY = 'SEND_INVITE_CONTACT_NOTIFY'

/* SEARCH */
export const GET_SEARCH_BY_TYPE = 'GET_SEARCH_BY_TYPE'
export const SET_SEARCH_BY_TYPE = 'SET_SEARCH_BY_TYPE'
export const CLEAR_SEARCH_BY_TYPE = 'CLEAR_SEARCH_BY_TYPE'

/* Gift */
export const CREATE_GIFT = 'CREATE_GIFT'

/* CHAT */
export const GET_CHAT_LIST = 'GET_CHAT_LIST'
export const SET_CHAT_LIST = 'SET_CHAT_LIST'

export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY'
export const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY'

export const ADD_CHAT_ITEM = 'ADD_CHAT_ITEM'

/* Give */
export const CREATE_GIVE = 'CREATE_GIVE'
export const UPDATE_GIVE = 'UPDATE_GIVE'
export const GET_GIVE_LIST = 'GET_GIVE_LIST'
export const SET_GIVE_LIST = 'SET_GIVE_LIST'
export const CLEAR_GIVE_LIST = 'CLEAR_GIVE_LIST'
export const GET_GIVE_COUNT = 'GET_GIVE_COUNT'
export const SET_GIVE_COUNT = 'SET_GIVE_COUNT'
export const REQUEST_GIVE_STATUS = 'REQUEST_GIVE_STATUS'
export const GET_RECURRING_LIST = 'GET_RECURRING_LIST'
export const SET_RECURRING_LIST = 'SET_RECURRING_LIST'
export const CLEAR_RECURRING_LIST = 'CLEAR_RECURRING_LIST'
export const GET_RECEIPT_LIST = 'GET_RECEIPT_LIST'
export const SET_RECEIPT_LIST = 'SET_RECEIPT_LIST'
export const CLEAR_RECEIPT_LIST = 'CLEAR_RECEIPT_LIST'
export const GET_MY_ACHIVEMENT_LIST = 'GET_MY_ACHIVEMENT_LIST'
export const SET_MY_ACHIVEMENT_LIST = 'SET_MY_ACHIVEMENT_LIST'
export const CLEAR_MY_ACHIVEMENT_LIST = 'CLEAR_MY_ACHIVEMENT_LIST'
export const GIVE_DONATE = 'GIVE_DONATE'
export const SET_GIVE_ID = 'SET_GIVE_ID'
export const CLEAR_GIVE_ID = 'CLEAR_GIVE_ID'
export const GIVE_SEND_RECEIPT = 'GIVE_SEND_RECEIPT'

/* Donate */
export const GET_DONATE_LIST = 'GET_DONATE_LIST'
export const SET_DONATE_LIST = 'SET_DONATE_LIST'
export const CANCEL_RECEIPT = 'CANCEL_RECEIPT'
export const CANCEL_RECEIPT_NOTIFY = 'CANCEL_RECEIPT_NOTIFY'
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT'
export const UPDATE_RECEIPT_NOTIFY = 'UPDATE_RECEIPT_NOTIFY'
export const GET_NONPROFIT_TOTAL_DONATION = 'GET_NONPROFIT_TOTAL_DONATION'
export const SET_NONPROFIT_TOTAL_DONATION = 'SET_NONPROFIT_TOTAL_DONATION'
export const GET_DONATION_LIST_TO_NONPROFIT = 'GET_DONATION_LIST_TO_NONPROFIT'
export const SET_DONATION_LIST_TO_NONPROFIT = 'SET_DONATION_LIST_TO_NONPROFIT'
export const GET_DONATION_LIST_TO_PROJECT = 'GET_DONATION_LIST_TO_PROJECT'
export const SET_DONATION_LIST_TO_PROJECT = 'SET_DONATION_LIST_TO_PROJECT'
export const UPLOAD_DONATION_LIST = 'UPLOAD_DONATION_LIST'
export const GET_RECURRING_LIST_TO_NONPROFIT = 'GET_RECURRING_LIST_TO_NONPROFIT'
export const SET_RECURRING_LIST_TO_NONPROFIT = 'SET_RECURRING_LIST_TO_NONPROFIT'